import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import dateFormat from 'dateformat';
import moment from 'moment';

import { Form, Row } from "react-bootstrap"
import { getUser } from "@myaccount/website-service"
import { navigate } from "gatsby";
import { useQuery} from '@apollo/client';
import gql from "graphql-tag"

import InputField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/input'
import SelectField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/select'
import TextAreaField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/textarea'
import CheckboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/checkbox'
import RadioboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/radio'
import ButtonField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/button'
import HtmlBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/html'
import ReCaptchaBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/recaptcha'
import 'gatsby-theme-starberry-lomondgroup/src/components/forms/assets/styles/_index.scss'
import { postFormData, api_form_error, putFormData } from "gatsby-theme-starberry-lomondgroup/src/components/forms/api/Api"
import { FormsSubject } from "gatsby-theme-starberry-lomondgroup/src/common/form-mail-subjects"
import { phoneNumberValidation, Mail_Function, Payment_Mail, getUrlParams } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis"
//import { OfficeList } from "gatsby-theme-starberry-lomondgroup/src/common/queries/common_use_query"

import { PageLinks } from "../../common/page-links";
import { epdqID } from "../../common/constant";


function PaymentForm(props) {
    /* ========== Office List ======== */
    const OfficeList = gql`
    query OfficeList{
      offices(sort:"sort:ASC", where:{publish:true}){
        id
        slug
        name
        email
        payment_email
      }
    }`

  const { loading, error, data } = useQuery(OfficeList);

  const location = useLocation();
  const userData = getUser();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrormsg, setShowerrorMsg] = useState("");
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [show_subscription, setShowSubscription] = useState(false);
  const [show_recurring_payment, setShowRecurringPayment] = useState(false);
  const [show_other_payment, setShowOtherPayment] = useState(false);
  const [show_no_payment, setNoPayment] = useState(false);
  const [subscription, setSubscription] = useState("");
  const [billing_same, setBillingSame] = useState(false);
  const [payment_type, setPaymentType] = useState('');

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const [selectedOptions, setSelectedOptions] = useState({persontype:"", salutation:"", billing_salutation: "", description: "", description2: ""});
  //const [selectError, setSelectError] = useState(false);
  const [selectError, setSelectError] = useState({persontype:"", salutation:"", billing_salutation:"", description: "", description2: ""});

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  var branch_list = [];

  if (data && data.offices && data.offices.length > 0) {
    data.offices.map((item, index) => {

      if(item.name && item.email){
        if(item?.payment_email){
          branch_list.push({label:item.name, value:item.name+","+item.payment_email});
        } else {
          branch_list.push({label:item.name, value:item.name+","+item.email});
        }
      }
      
    })
  }

  //console.log("branch_list", branch_list);


  var to_email_id = location?.state?.to_email_id ? location?.state?.to_email_id : props.to_email_id ? props.to_email_id : "";

  var person_type = [
    {label:"Tenant", value:"Tenant"},
    {label:"Landlord", value:"Landlord"},
    {label:"Buyer", value:"Buyer"},
    {label:"Vendor", value:"Vendor"},
    {label:"Leaseholder (Block Management)", value:"Leaseholder"},
    {label:"Guarantor", value:"Guarantor"}
  ]

  var name_title = ["Mr", "Mrs", "Miss", "Dr"];
  var payment_related = [
    {label:"Rent", value:"Rent"},
    {label:"Tenancy Completion Monies", value:"Tenancy Completion"},
    {label:"Application Fees", value:"Application Fees"},
    {label:"Landlord Fees", value:"Landlord Fees"},
    {label:"Maintenance works", value:"Maintenance works"},
    {label:"Insurance", value:"Insurance"},
    {label:"Sales Fees", value:"Sales Fees"},
    {label:"Service Charge", value:"Service Charge"},
    {label:"Other", value:"Other"},
  ]
  var subscription_type = [
    {label:"Monthly", value:"month", subscription_value:1},
    {label:"Quarterly", value:"quarter", subscription_value:3},
    {label:"Six Months", value:"6 months", subscription_value:6},
  ];
  var department = [
    {label:"Accounts (Rental Payments)", value:"accounts_rental_payment"},
    {label:"Altrincham", value:"altrincham"},
    {label:"Hale", value:"hale"},
    {label:"Sale", value:"sale"},
    {label:"Monton", value:"monton"},
    {label:"Didsbury", value:"didsbury"},
    {label:"Fallowfield", value:"fallowfield"},
    {label:"Prestwich", value:"prestwich"},
    {label:"Manchester City Centre", value:"manchester_city_centre"},
  ];

  var tenancy_length = [
    {label:"6 Months", value:6},
    {label:"12 Months", value:12},
    {label:"18 Months", value:18},
    {label:"24 Months", value:24},
  ];

  var setup_recurring_payment = [
    {label:"Yes - I wish to setup a recurring payment", value:"Yes"},
    {label:"No - this is a one-off payment", value:"No"},
  ];

  var recurring_on = [];
  for(var i=1; i <= 31; i++){
    recurring_on.push(i);
  }

  var start_date = new Date();
  start_date.setDate(start_date.getDate());

  var end_date = startDate ? new Date(startDate) : start_date;
  end_date.setDate(end_date.getDate() + 1);

  //console.log("startDate", startDate);


  var fields = ([
    {
      element: "config",
      formname: "Online Payment",
      form_type: "Online Payment",
      error_text: "Highlighted fields are required.",
      success_text: "Thank you for submitting information, it will be redirect to payment verification page.",
      email_server_func: "online-payment",
      event_tracking: "online-payment",
      page_url: location.href,
      email_temp_user: "payment_user",
      email_temp_admin: "payment_admin",
      email_subject_user: FormsSubject.payments.user_subject,
      email_subject_admin: FormsSubject.payments.admin_subject
    },
    {
        grpmd: "12",
        label: "Firstly, are you a tenant, landlord, buyer or vendor?",
        name: "persontype",
        element: "select",
        required: true,
        values: person_type,
        divclass: "form-wrap",
        labelClass: "annual-txt",
        class: "full_wdth_box",
        option_with_label:true
    },
    // {
    //     grpmd: "12",
    //     label: "To help us allocate the payment, please select which department/branch the payment is for...",
    //     name: "department",
    //     element: "select",
    //     required: true,
    //     values: department,
    //     divclass: "form-wrap",
    //     labelClass: "annual-txt",
    //     class: "full_wdth_box",
    //     option_with_label:true
    // },
    
    {
        text: 'Your details...',
        element: "html",
        class: "mt-3 content_r-m form-label"
    },
    {
        grpmd: "4",
        label: "",
        name: "salutation",
        element: "select",
        placeholder: "Title*",
        required: true,
        values: name_title,
        divclass: "form-wrap",
        labelClass: "annual-txt",
        class: "full_wdth_box",
        option_with_label:false
    },
    {
      grpmd: "4",
      label: "",
      placeholder: "First Name*",
      name: "first_name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
      labelClass: "annual-txt",
      errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
      divclass: "form-wrap",
    },
    {
        grpmd: "4",
        label: "",
        placeholder: "Last Name*",
        name: "last_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Email Address",
      placeholder: "",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
      labelClass: "annual-txt",
      errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM.",
      divclass: "form-wrap",
      //defaultValue: userData?.email || ``
    },
    // {
    //   grpmd: "12",
    //   label: "Phone Number",
    //   placeholder: "",
    //   name: "telephone",
    //   type: "text",
    //   element: "input",
    //   required: true,
    //   patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
    //   labelClass: "annual-txt",
    //   errorMsg: "Enter a valid phone number, Ex: 01234 567 890.",
    //   divclass: "form-wrap",
    //   defaultValue: userData?.mobile || ``
    // },
    {
        text: 'Property to which your payment relates...',
        element: "html",
        class: "mt-3 content_r-m form-label"
    },
    // {
    //     grpmd: "12",
    //     label: "",
    //     placeholder: "Name/Number*",
    //     name: "property_name_number",
    //     type: "text",
    //     element: "input",
    //     required: true,
    //     labelClass: "annual-txt",
    //     divclass: "form-wrap",
    // },
    {
        grpmd: "12",
        label: "",
        placeholder: "Address*",
        name: "property_address_line_1",
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap",
    },
    // {
    //     grpmd: "12",
    //     label: "",
    //     placeholder: "Address Line 2",
    //     name: "property_address_line_2",
    //     type: "text",
    //     element: "input",
    //     required: false,
    //     labelClass: "annual-txt",
    //     divclass: "form-wrap",
    // },
    {
        grpmd: "6",
        label: "",
        placeholder: "Town/City*",
        name: "property_town_city",
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap",
    },
    {
        grpmd: "6",
        label: "",
        placeholder: "Postcode*",
        name: "property_postcode",
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap",
    },

    {
        grpmd: "12",
        label: "Billing Address is the same as the property address?",
        placeholder: "Billing Address is the same as the property address?",
        name: "billing_same",
        type: "checkbox",
        element: "checkbox",
        required: false,
        value:"yes",
        class: "checkbox_cnt",
        checked: billing_same ? "checked" : false
    },
  ])

  {/* ===== Billing Info ==== */}
  {!billing_same &&
    fields.push(
      {
        text: 'Cardholder details...',
        element: "html",
        class: "mt-3 content_r-m form-label"
      },
      {
        grpmd: "4",
        label: "",
        name: "billing_salutation",
        element: "select",
        placeholder: "Title*",
        required: true,
        values: name_title,
        divclass: "form-wrap",
        labelClass: "annual-txt",
        class: "full_wdth_box",
        option_with_label:false
      },
      {
        grpmd: "4",
        label: "",
        placeholder: "Billing First Name*",
        name: "billing_first_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap",
      },
      {
        grpmd: "4",
        label: "",
        placeholder: "Billing Last Name*",
        name: "billing_last_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap",
      },
      // {
      //   grpmd: "12",
      //   label: "",
      //   placeholder: "Name/Number*",
      //   name: "billing_property_name_number",
      //   type: "text",
      //   element: "input",
      //   required: true,
      //   labelClass: "annual-txt",
      //   divclass: "form-wrap",
      // },
      {
        grpmd: "12",
        label: "",
        placeholder: "Billing Address*",
        name: "billing_property_address_line_1",
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap",
      },
      // {
      //   grpmd: "12",
      //   label: "",
      //   placeholder: "Billing Address Line 2",
      //   name: "billing_property_address_line_2",
      //   type: "text",
      //   element: "input",
      //   required: false,
      //   labelClass: "annual-txt",
      //   divclass: "form-wrap",
      // },
      {
        grpmd: "6",
        label: "",
        placeholder: "Billing Town/City*",
        name: "billing_property_town_city",
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap",
      },
      {
        grpmd: "6",
        label: "",
        placeholder: "Billing Postcode*",
        name: "billing_property_postcode",
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap",
      },
    )
  }

  fields.push(
    {
        text: 'Payment details...',
        element: "html",
        class: "mt-3 content_r-m form-label"
    },
    {
      text: 'The amount you enter here will be debited TODAY using the card you choose next.',
      element: "html",
      class: "mt-0 content_r-m",
    },
    {
      text: 'Note: If you DO NOT wish to make a payment TODAY then you must enter £0.00 below. You can then continue on to setup a recurring card payment.',
      element: "html",
      class: "mt-1 content_r-m form-label red_text",
    },
    {
        grpmd: "6",
        label: "",
        placeholder: "Amount (£)*",
        name: "amount_full",
        type: "number",
        element: "input",
        required: true,
        patternchk: "",
        labelClass: "annual-txt",
        divclass: "form-wrap",
        min:"0",
        step:"1"
    },
    {
        grpmd: "6",
        label: "",
        placeholder: "Amount (p) *",
        name: "amount_pence",
        type: "number",
        element: "input",
        required: true,
        patternchk: "",
        labelClass: "annual-txt",
        divclass: "form-wrap",
        min:"00",
        step:"1",
        max:"99",
        defaultValue:"00",
        maxlength:2
    },
    {
        grpmd: "12",
        label: "What does the payment relate to?",
        name: "description",
        element: "select",
        required: true,
        values: payment_related,
        divclass: "form-wrap",
        labelClass: "annual-txt",
        class: "full_wdth_box",
        option_with_label:true
    }
  )

  {show_other_payment &&
    fields.push(  
      {
        grpmd: "12",
        label: "",
        placeholder: "If other please comment...",
        name: "comments",
        element: "textarea",
        class: "",
        required: false,
        rows: "4",
        labelClass: "annual-txt",
        divclass: "mb-4",
      }
  )}
  
  {/* ===== Subscription Info ==== */}
  {show_recurring_payment &&
    fields.push(  
      {
        grpmd: "12",
        label: "Do you wish to setup a recurring payment? e.g. for monthly Rent on a tenancy",
        name: "repeating_payment",
        element: "select",
        required: true,
        values: setup_recurring_payment,
        placeholder: "Choose from...",
        divclass: "form-wrap",
        labelClass: "annual-txt",
        class: "full_wdth_box",
        option_with_label:true
    },
  )}

  {show_no_payment &&
    fields.push(  
      {
        text: '<b>NOTE:</b> You are more likely to fall into arrears if you do not set up a recurring payment. If you do not wish to set this up on here, alternatively you should set up a Standing Order with your bank using the following details:',
        element: "html",
        class: "mt-0 mb-3 content_r-m",
      },
  )}

  {show_subscription &&
    fields.push(  
      {
          grpmd: "12",
          label: "Subscription type..",
          name: "description2",
          element: "select",
          required: true,
          values: subscription_type,
          placeholder: "Choose from...",
          divclass: "form-wrap",
          labelClass: "annual-txt",
          class: "full_wdth_box",
          option_with_label:true
      },
      {
          text: 'Subscription amount',
          element: "html",
          class: "mt-0 content_r-m form-label"
      },
      {
          grpmd: "6",
          label: "",
          placeholder: "Amount (£)*",
          name: "subscription_amount_full",
          type: "number",
          element: "input",
          required: true,
          patternchk: "",
          labelClass: "annual-txt",
          divclass: "form-wrap",
          min:"1",
          step:"1"
      },
      {
          grpmd: "6",
          label: "",
          placeholder: "Amount (p) *",
          name: "subscription_amount_pence",
          type: "number",
          element: "input",
          required: true,
          patternchk: "",
          labelClass: "annual-txt",
          divclass: "form-wrap",
          min:"00",
          step:"1",
          max:"99",
          defaultValue:"00",
          maxlength:2
      },

      {
        text: '<b>NOTE:</b> The amount specified will be taken at the frequency specified, 3 days in advance, of rent due date. As you are not asking the Rent due date, but instead the date the payment is recurring from, I think the disclaimer should say: The amount specified will be taken at the frequency specified, 3 days in advance of the date selected to allow time for the payment to clear before your rent due date.',
        element: "html",
        class: "mb-4 content_r-m",
      },
      
      // {
      //     grpmd: "6",
      //     label: "Payment Start Date",
      //     placeholder: "",
      //     name: "payment_start_date",
      //     type: "date",
      //     element: "input",
      //     required: true,
      //     labelClass: "annual-txt",
      //     class:"date_field form-control",
      //     divclass: "form-wrap",
      //     min:dateFormat(start_date, "yyyy-mm-dd")
      // },
      {
        grpmd: "6",
        label: "Payment Start Date",
        placeholder: "",
        name: "SUB_STARTDATE",
        type: "date",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        class:"date_field form-control",
        divclass: "form-wrap",
        min:dateFormat(start_date, "yyyy-mm-dd")
      },
      {
        grpmd: "6",
        label: "Payment Period",
        name: "tenancy_length",
        element: "select",
        required: true,
        values: tenancy_length,
        divclass: "form-wrap",
        labelClass: "annual-txt",
        class: "full_wdth_box",
        option_with_label:true
      },

      payment_type && (
      {
        text: `<b>Recurring Payment Information:</b><br/> The recurring payment will be taken <b>3 days</b> prior to the payment due date and will be taken every <b>${payment_type}</b> until the end of the tenancy period.`,
        element: "html",
        class: "mb-4 content_r-m",
      }),
      
      // {
      //     grpmd: "6",
      //     label: "",
      //     placeholder: "",
      //     name: "SUB_ENDDATE",
      //     type: "date",
      //     element: "input",
      //     required: true,
      //     labelClass: "annual-txt",
      //     class:"date_field form-control",
      //     divclass: "form-wrap",
      //     min:dateFormat(end_date, "yyyy-mm-dd")
      // },
    )
  }

  fields.push(
    {
      grpmd: "12",
      label: "Additional notes",
      placeholder: "Please use payment reference here...",
      name: "additional_notes",
      element: "textarea",
      required: true,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Branch",
      name: "branch",
      element: "select",
      required: true,
      values: branch_list,
      divclass: "form-wrap",
      labelClass: "annual-txt",
      class: "full_wdth_box",
      option_with_label:true
    },
    {
        text: 'Terms and Conditions for payments made online',
        element: "html",
        class: "mt-3 content_r-m form-label"
    },
    {
        text: "Online payments are non-refundable, with the exception of incorrect amounts being paid or overpayments. In the event an incorrect payment or overpayment is made, we will repatriate such monies within 7 working days. <br />Please note all of the company contact details are listed within the 'Contact Us` section of this website. <br />No charges are incurred for payments by debit or credit card.",
        element: "html",
        class: "mt-0 content_r-m"
    },
    {
      grpmd: "12",
      name: "SUBMIT",
      type: "submit",
      element: "button",
      value: "Proceed to Verification",
      class: "btn btn-green",
      labelClass: "",
      customClass: "custom-button-wrapper mt-4",
      divclass: "form-wrap"
    },
    {
      text: 'By proceeding, you agree to the terms set out in our <a href="/' + PageLinks.privacy_policy + '/">Privacy Policy.</a>',
      element: "html",
      class: "mt-3 text-center content_r-m"
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef
    },
  );

  const handlechange = event => {
    // remove initial empty spaces

    //console.log("event check ==>", event);

    if (event.type === "select") {
      //event.value = event.value.trimStart()
      var event_name = event.name;

      // Show subdcription Yes/No
      if(event.name === "description"){
        if(event.value === "Rent" || event.value === "Tenancy Completion"){
          setShowRecurringPayment(true)
        } else{
          setShowSubscription(false);
          setSubscription("");
          setShowRecurringPayment(false)
        }

        // if(event.value === "Rent"){
        //   setShowSubscription(true);
        //   setSubscription("1");
        // } else{
        //   setShowSubscription(false);
        //   setSubscription("");
        // }

        if(event.value === "Other"){
          setShowOtherPayment(true)
        } else{
          setShowOtherPayment(false)
        }
      }

      // Show subdcription fields
      if(event.name === "repeating_payment"){
        if(event.value === "Yes"){
          setShowSubscription(true);
          setSubscription("1");
        } else{
          setShowSubscription(false);
          setSubscription("");
        }

        if(event.value === "No"){
          setNoPayment(true);
        } else{
          setNoPayment(false);
        }
      }

      // Set subscription value monthly/quarterly/6 months
      if(event.name === "description2"){
        //document.getElementsByName("SUB_PERIOD_NUMBER")[0].value === event.subscription_value
        if(event.subscription_value){
          setSubscription(event.subscription_value);
          setPaymentType(event.value);
        } else{
          setSubscription("");
        }
      }

      //setSelectedOptions({...selectedOptions, [event.name]: event});
      setSelectError({...selectError, [event.name]:false})
    } else {
      if (event.target.name === "SUB_STARTDATE") {
        setStartDate(event.target.value)
      }

      if (event.target.name === "billing_same") {
        setBillingSame(!billing_same)
      }

      if (event.target.name === "telephone") {
        var valid_phone = phoneNumberValidation(event.target.value);
        if (valid_phone) {
          event.target.value = valid_phone;
        } else {
          event.target.value = '';
        }
      } else {
        if (event.target.name === "subscription_amount_pence" || event.target.name === "amount_pence") {
          //console.log("target_value", event.target.name, event.target.value?.length)
          if(event.target.value?.length < 3){
            //console.log("target_value 2", event.target.name, event.target.value?.length)
            event.target.value = event.target.value.trimStart()
          } else{
            //console.log("target_value 3", event.target.name, event.target.value?.length)
            event.target.value = event.target.value.trimStart().substr(0,2)
          }
        } else{
          event.target.value = event.target.value.trimStart()
        }
      }
    }
  }


  useEffect(() => {

    //console.log("token", token); 

    if (token !== '') {

      const processFromData = async () => {

        let formData = new FormData();

        formData.append('data', JSON.stringify(formvalues));
        setShowerror(false);

        postFormData(formData).then(async apiRes => {

          //console.log("apiRes", apiRes);

          window.grecaptcha.reset();

          if (apiRes?.success === true) {
            // tracking event
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'formSubmit',
              'formType': 'form-' + fields[0].event_tracking,
              'formId': 'form-' + fields[0].event_tracking,
              'formName': fields[0].formname,
              'formLabel': fields[0].formname
            });

            //console.log("formvalues", formvalues);


            //await Payment_Mail(formvalues);

            localStorage.setItem("payment_id", apiRes?.form?.id)
            //localStorage.setItem("payment_info", JSON.stringify(apiRes?.form))

            var cur_date = new Date();
            var order_id = `${dateFormat(cur_date, "yyyymmddhMMss")}-${apiRes?.form?.id}`

            var update_form_data = {
              extra: {...apiRes?.form?.extra, "ORDERID":order_id}
            }

            putFormData(update_form_data, apiRes?.form?.id).then(async apiRes2 => {
              //console.log("apiRes2", apiRes2);
              if (apiRes2?.success === true) {
                navigate(`/${PageLinks.payment_verify}/`)
              }
            });

            //navigate(`/${PageLinks.payment_verify}/`)

            setThankyou(true);
            setTimeout(() => {
              setThankyou(false)
            }, 6000);

            //await Payment_Mail(apiRes?.form);
          } else {
            setShowerror(true);
            setThankyou(false);
            setShowerrorMsg(api_form_error)
          }

          setToken("");

        });

        formvalues['g-recaptcha-response'] = token;

        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }


      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      setShowerrorMsg(fields[0].error_text)
      
      form_obj_arr.map(item => {
        setSelectError(prevState => ({ ...prevState, [item[1].name]:item[1]?.required}));
      })

      // if (selectedOptions?.value) {
      //   setSelectError(false);
      // } else {
      //   setSelectError(true);
      // }
      //console.log("select_err", select_err)

      //setSelectError(select_err)

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else {

      event.preventDefault();
      const formsdata = (event.target);

      //console.log("event => formsdata", formsdata);

      const json = {}
      Object.keys(formsdata).map(key => {

        if (formsdata[key].type === "checkbox") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = 'yes'
          }
        } else if (formsdata[key].type === "radio") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = formsdata[key].value
          }
        } else {
          if(formsdata[key].value !== ""){
            json[formsdata[key].name] = formsdata[key].value
          }
        }

      })

      var date_time = dateFormat(startDate, "mmm dd, yyyy") + " " + dateFormat(startTime, "hh:MM TT");
      //json['name'] = json.first_name+' '+json.last_name;
      var params = getUrlParams();
      //console.log("filtered_team", params);

      var amount_pence = json.amount_pence ? json.amount_pence.length === 1 ? json.amount_pence+"0": json.amount_pence : "00";

      var branch_split = json.branch ? json.branch.split(',') : "";

      json['name'] = json.first_name+' '+json.last_name;
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;

      json['branch'] = branch_split.length > 0 ? branch_split[0] : json.branch;
      if (branch_split.length > 0) {
        var to_email_ids = branch_split[1]
        if(to_email_id){
          to_email_ids += ", "+to_email_id
        }
        json['to_email_id'] = to_email_ids;
      }

      json['billing_salutation'] = billing_same ? json.salutation : json.billing_salutation;
      json['billing_first_name'] = billing_same ? json.first_name : json.billing_first_name;
      json['billing_last_name'] = billing_same ? json.last_name : json.billing_last_name;
      // json['billing_property_name_number'] = billing_same ? json.property_name_number : json.billing_property_name_number;
      json['billing_property_address_line_1'] = billing_same ? json.property_address_line_1 : json.billing_property_address_line_1;
      json['billing_property_address_line_2'] = billing_same ? json.property_address_line_2 : json.billing_property_address_line_2;
      json['billing_property_town_city'] = billing_same ? json.property_town_city : json.billing_property_town_city;
      json['billing_property_postcode'] = billing_same ? json.property_postcode : json.billing_property_postcode;

      //var cn_name = json.billing_salutation+" "+json.billing_first_name+" "+json.billing_last_name;
      var cn_name = json.billing_first_name+" "+json.billing_last_name;

      json['AMOUNT'] = json.amount_full.toString() + amount_pence.toString();
      json['CN'] = cn_name;
      json['EMAIL'] = json.email;
      //json['OWNERADDRESS'] = json.billing_property_name_number +" "+ json.billing_property_address_line_1;
      json['OWNERADDRESS'] = json.billing_property_address_line_1;
      json['OWNERADDRESS2'] = json.billing_property_address_line_2;
      json['OWNERTOWN'] = json.billing_property_town_city;
      json['OWNERZIP'] = json.billing_property_postcode;

      // var complus_object = {
      //   p_email: json.email, 
      //   p_persontype: json.persontype, 
      //   p_department: json.department, 
      //   p_name: json.salutation+" "+json.first_name+" "+json.last_name, 
      //   p_address: json.property_name_number+" "+json.property_address_line_1+" "+json.property_address_line_2+" "+json.property_town_city+" "+json.property_postcode,
      //   p_description: json.p_description,
      //   p_comments: json.comments
      // }
      // json['COMPLUS'] = window.btoa(JSON.stringify(complus_object));

      if(show_subscription){
        var subscription_start = moment(json.SUB_STARTDATE).subtract(3, 'days');

        var subscription_id = "SUB_" + json.billing_property_address_line_1+json.billing_property_postcode;
        var sub_comment = `${json.billing_property_address_line_1}_${json.billing_property_postcode}_${subscription_id}`;

        json['SUBSCRIPTION_ID'] = subscription_id.replace(/ /g,'');
        json['SUB_AMOUNT'] = json.subscription_amount_full.toString() + json.subscription_amount_pence.toString();
        json['SUB_ORDERID'] = subscription_id;
        json['SUB_COM'] = sub_comment.replace(/ /g,'');

        json['SUB_STARTDATE'] = moment(subscription_start).add(subscription, 'months').format("YYYY-MM-DD");
        json['SUB_ENDDATE'] = moment(subscription_start).add((json.tenancy_length - subscription), 'months').format("YYYY-MM-DD");
        //console.log("tenancy_length", json.tenancy_length, moment(subscription_start).add(json.tenancy_length, 'months').format("YYYY-MM-DD"))
        json['SUB_PERIOD_UNIT'] = "m";
        json['SUB_STATUS'] = "1";
        json['SUB_PERIOD_MOMENT'] = dateFormat(subscription_start, "dd");
      }
      

      //console.log("formsdata_json", json);

      setFormvalues(json);
      if(process.env.GATSBY_CAPTCHA_MODE === "test"){
        setToken("test");
      } else{
        recaptchaRef.current.execute();
      }

      setValidated(false);
      setSelectError(false);
      setSelectedOptions({});

      // reset form
      // const form = event.target
      // form.reset();
      // setStartDate();
      // setStartTime();

    }
  };

  //console.log("selectedOptions check ===>", selectedOptions, selectError);

  //console.log("end_date", moment(subscription_start).add(json.tenancy_length, 'months').format("YYYY-MM-DD"));

  return (
    <div className="form">

      {showerror && <div className="alert alert-danger">
        {showerrormsg}
      </div>}
      <div ref={myRef}></div>

      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}


      <Form name={fields[0].formname} className={props.classNames} action="/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">

        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={fields[0].formname} />
        <input type="hidden" name="to_email_id" value={to_email_id} />
        <input type="hidden" name="admin_email" value={"Yes"} />

        {/* ============== Payment Fields ============== */}
        <input type="hidden" name="accept_rules" value={"on"} />
        <input type="hidden" name="PSPID" value={epdqID} />
        <input type="hidden" name="ORDERID" value="" />
        <input type="hidden" name="AMOUNT" value="" />
        <input type="hidden" name="CURRENCY" value="GBP" />
        <input type="hidden" name="LANGUAGE" value="en_US" />


        <input type="hidden" name="MANDATEID" value="" />
        <input type="hidden" name="SIGNDATE" value="" />
        <input type="hidden" name="SEQUENCETYPE" value="" />

        <input type="hidden" name="TITLE" value="Online Payment" />
        <input type="hidden" name="BGCOLOR" value="" />
        <input type="hidden" name="TXTCOLOR" value="" />
        <input type="hidden" name="TBLBGCOLOR" value="" />
        <input type="hidden" name="TBLTXTCOLOR" value="" />
        <input type="hidden" name="BUTTONBGCOLOR" value="86005e" />
        <input type="hidden" name="BUTTONTXTCOLOR" value="FFFFFF" />
        <input type="hidden" name="LOGO" value="./logo.png" />
        <input type="hidden" name="FONTTYPE" value="" />
        <input type="hidden" name="TP" value="" />

        <input type="hidden" name="ACCEPTURL" value={`${process.env.GATSBY_SITE_URL}/${PageLinks.payment_success}`} placeholder="ACCEPTURL" />
        <input type="hidden" name="BACKURL" value={`${process.env.GATSBY_SITE_URL}/${PageLinks.online_payment}`} placeholder="BACKURL" />
        <input type="hidden" name="CANCELURL" value={`${process.env.GATSBY_SITE_URL}/${PageLinks.payment_failed}`} placeholder="cancelurl" />
        <input type="hidden" name="DECLINEURL" value={`${process.env.GATSBY_SITE_URL}/${PageLinks.payment_failed}`} placeholder="declineurl" />
        {/* <input type="hidden" name="EXCEPTIONURL" value={`${process.env.GATSBY_SITE_URL}/${PageLinks.payment_failed}`} placeholder="exceptionurl" /> */}
        <input type="hidden" name="HOMEURL" value={process.env.GATSBY_SITE_URL} placeholder="homeurl" />

        <input type="hidden" name="COM" value="" />
        <input type="hidden" name="CN" value="" />
        <input type="hidden" name="EMAIL" value="" />
        <input type="hidden" name="PM" value="" />
        <input type="hidden" name="BRAND" value="" />
        {/* <input type="hidden" name="OWNERZIP" value="" />
        <input type="hidden" name="OWNERADDRESS" value="" />
        <input type="hidden" name="OWNERADDRESS2" value="" />
        <input type="hidden" name="OWNERTOWN" value="" /> */}
        <input type="hidden" name="OWNERCTY" value="UK" />

        <input type="hidden" name="COMPLUS" value="" />
        <input type="hidden" name="PARAMPLUS" value="" />

        {/* ============ Subscription fields =========== */}
        <input type="hidden" name="SUBSCRIPTION_ID" value="" required="required" />
        <input type="hidden" name="SUB_AMOUNT" value="" required="required" />
        <input type="hidden" name="SUB_COM" value="" required="required" />
        <input type="hidden" name="SUB_ORDERID" value="" required="required" />
        <input type="hidden" name="SUB_PERIOD_UNIT" value="" required="required" />
        <input type="hidden" name="SUB_PERIOD_NUMBER" value={subscription} required="required" />
        <input type="hidden" name="SUB_STATUS" value="" required="required" />

        {/* ============== Payment Fields ============== */}


        <input type="hidden" name="bot-field" />

        <Row className="custom-text-field-wrapper no_direction">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={(field.type === 'valuation_date') ? startDate : ''}
                  setStartDate={(field.type === 'valuation_date')  ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                  defaultValue={field.defaultValue ? field.defaultValue : ""}
                  min={field.min ? field.min : ""}
                  step={field.step ? field.step : ""}
                  maxlength={field.maxlength}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  //selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError[field.name]}
                  //isError= {Object.keys(selectError).length > 0 ? (selectError[field.name] ? '' : true) : ''}
                  optionWithLabel={field.option_with_label}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>

                  <TextAreaField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    errorMsg={field.errorMsg}
                  />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  checked={field.checked}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                  divclass={field.divclass}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <>

                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                    grpmd={field.grpmd}
                    customClass={field.customClass}
                  />

                </>
              );
            }
          })
          }
        </Row>
      </Form>


    </div>
  );
}


const PaymentFormPage = (props) => (
  <PaymentForm {...props} />

)

export default PaymentFormPage